var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" content"},[_c('base-form',{ref:"fromdata",staticClass:"formStyle",attrs:{"componentList":_vm.from,"formAttrs":{
      model: _vm.queryParas,
      labelWidth: '90px',
    },"type":_vm.type},on:{"handleFilter":_vm.handleFilter,"clearParams":_vm.clearParams}}),_c('base-table',{ref:"tableData",staticClass:"main-page-table",attrs:{"columns":_vm.columns,"showPage":true,"tableAttrs":{
      data: _vm.tableData,
      stripe: true,
      border:false
    },"api":_vm.api,"getApi":'getPage',"queryParas":_vm.queryParas,"loadCount":_vm.loadCount,"dataSource":_vm.tableData,"webPage":false},on:{"update:queryParas":function($event){_vm.queryParas=$event},"update:query-paras":function($event){_vm.queryParas=$event},"update:loadCount":function($event){_vm.loadCount=$event},"update:load-count":function($event){_vm.loadCount=$event},"update:dataSource":function($event){_vm.tableData=$event},"update:data-source":function($event){_vm.tableData=$event}},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}},{key:"action",fn:function(scope){return [(parseInt(scope.row.statusCode) !== 80)?_c('icon-button',{attrs:{"content":"修改","icon":"iconfont iconbianji3"},on:{"click":function($event){$event.preventDefault();return _vm.goNextPage('/repaymentplaninfo', { type: 'BG',loanId:scope.row.loanId,name:'还款计划管理' })}}}):_vm._e(),_c('icon-button',{attrs:{"content":"查看","icon":"iconfont iconchakan"},on:{"click":function($event){$event.preventDefault();return _vm.goNextPage('/repaymentplaninfo', { type: 'CK',loanId:scope.row.loanId,name:'还款计划管理' }, scope.row)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }