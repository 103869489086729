
import BaseSelect from '@/components/common/base-select/base-select'
// import { validbusinessNo } from '@/utils/validate'
export const fromConfig = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      // rules: [{ required: false, validator: validbusinessNo, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商',
      prop: 'gysId',
      tag: BaseSelect,
      attrs: {
        filterable: true,
        placeholder: '请选择',
        options: content.supplierApiList,
        selectedField: ['keyId', 'enterpriseName']
      }
    }
  ]
}
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      'show-overflow-tooltip': true
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      'show-overflow-tooltip': true
    },
    {
      label: '放款金额(元)',
      prop: 'loanAmount',
      'show-overflow-tooltip': true,
      formatter: row => {
        return (row.loanAmount ? row.loanAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '预计还款金额(元)',
      prop: 'billAmountsign',
      'show-overflow-tooltip': true,
      childs: [
        {
          label: '本金(元)',
          prop: 'principal',
          'header-align': 'center',
          minWidth: '120px',
          formatter: row => {
            return (row.principal ? row.principal.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
          }
        },
        {
          label: '服务费(元)',
          prop: 'serviceCharge',
          minWidth: '120px',
          'header-align': 'center',
          'show-overflow-tooltip': true,
          formatter: row => {
            return (row.serviceCharge ? row.serviceCharge.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
          }
        },
        {
          label: '总金额(元)',
          prop: 'totalamount',
          minWidth: '120px',
          'header-align': 'center',
          'show-overflow-tooltip': true,
          formatter: row => {
            return Number(row.serviceCharge ? row.serviceCharge : 0) + Number(row.principal) ? ((Number(row.serviceCharge ? row.serviceCharge : 0) + Number(row.principal))).toLocaleString('en-US', { minimumFractionDigits: 2 }) : ''
          }
        }
      ]
    },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
