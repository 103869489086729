// 还款计划管理主页
<template>
  <div class=" content">
    <!--表单-->
    <base-form
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      :type="type"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="fromdata"
    />
    <!--表格-->
    <base-table
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data: tableData,
        stripe: true,
        border:false
      }"
      :api="api"
      :getApi="'getPage'"
      :queryParas.sync="queryParas"
      :loadCount.sync="loadCount"
      :dataSource.sync="tableData"
      ref="tableData"
      :webPage="false"
      class="main-page-table"
    >
      <!--序号-->
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <!-- 操作-->
      <template slot="action" slot-scope="scope">
        <!-- 修改：已进行还款且未还款完成时，则可显示该按钮 -->
        <icon-button
          @click.prevent="goNextPage('/repaymentplaninfo', { type: 'BG',loanId:scope.row.loanId,name:'还款计划管理' })"
          content="修改"
          icon="iconfont iconbianji3"
          v-if="parseInt(scope.row.statusCode) !== 80"/>
        <icon-button
          @click.prevent="goNextPage('/repaymentplaninfo', { type: 'CK',loanId:scope.row.loanId,name:'还款计划管理' }, scope.row)"
          content="查看"
          icon="iconfont iconchakan"/>
      </template>
    </base-table>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import BaseForm from '@/components/common/base-form/base-form'
import BaseTable from '@/components/common/table/base-table/base-table'
import { fromConfig, columnsConfig } from './utils/config.js'
import { PlanDetailApi } from '@/api/businessApi'
import { supplierApi } from '@/api/companyApi'

export default {
  name: 'repayMentPlan',
  components: {
    BaseForm,
    BaseTable,
    IconButton
  },
  data () {
    return {
      type: '',
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        businessNo: this.businessNo,
        gysId: this.gysId
      },
      loadCount: 0,
      tableData: [], // 表格数据
      supplierApiList: [] // 供应商下拉列表
    }
  },

  computed: {
    columns () {
      return columnsConfig(this)
    },
    from () {
      return fromConfig(this)
    },
    api () {
      return PlanDetailApi
    },
    supplierApi () {
      return supplierApi
    }
  },
  mounted () {
    this.getSupplierApiList()
    this.handleFilter()
  },
  methods: {
    // 跳转
    goNextPage (url, data) {
      this.$router.push({
        path: url,
        query: data
      })
    },
    /**
     * 获取供应商
     */
    async getSupplierApiList () {
      try {
        const supplierList = await this.supplierApi.getSupplier()
        if (supplierList) {
          this.supplierApiList = supplierList.data
        }
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * 重置
     */
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
    },
    /**
     * 查询
     */
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    }
  },
  created () {
    this.type = this.$route.query.type
  }
}
</script>
<style lang="scss" scoped>
.pd15 {
  padding: 15px;
}

.content {
  height: 100%;
}
</style>
